<template>
  <div>
    <el-card>
      <div class="header" v-if="userInfo == null">
        <el-form
          ref="queryFormRef"
          :inline="true"
          :model="queryForm"
          class="demo-form-inline"
        >
          <el-form-item prop="userId">
            <el-input
              v-model="queryForm.userId"
              placeholder="用户ID"
            ></el-input>
          </el-form-item>
          <el-form-item prop="phone">
            <el-input v-model="queryForm.phone" placeholder="手机号"></el-input>
          </el-form-item>
          <el-form-item prop="fullname">
            <el-input
              v-model="queryForm.fullname"
              placeholder="受益人"
            ></el-input>
          </el-form-item>
          <el-form-item prop="productId">
            <el-select
              v-model="queryForm.productId"
              filterable
              remote
              reserve-keyword
              clearable
              placeholder="请输入项目名称"
              :remote-method="productRemoteMethod"
              :loading="loading"
            >
              <el-option
                v-for="item in productOptions"
                :key="item.id"
                :label="item.title"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="moneyStart">
            <el-input
              v-model="queryForm.moneyStart"
              placeholder="金额范围"
              style="width: 100px;"
            ></el-input>
          </el-form-item>
          -
          <el-form-item prop="moneyEnd" style="margin-left: 10px">
            <el-input
              v-model="queryForm.moneyEnd"
              placeholder="元"
              style="width: 100px;"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-date-picker
              v-model="paymentTime"
              type="daterange"
              range-separator="-"
              start-placeholder="打款日开始日期"
              end-placeholder="打款日结束日期"
              :unlink-panels="true"
              @calendar-change="selectPaymentTimeCalendarChange"
              @change="timeChangeHandle"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-date-picker
              v-model="expireTime"
              type="daterange"
              range-separator="-"
              start-placeholder="到期日开始日期"
              end-placeholder="到期日结束日期"
              :unlink-panels="true"
              @calendar-change="selectExpireTimeCalendarChange"
              @change="timeChangeHandle"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-date-picker
              v-model="foundedTime"
              type="daterange"
              range-separator="-"
              start-placeholder="成立日开始日期"
              end-placeholder="成立日结束日期"
              :unlink-panels="true"
              @calendar-change="selectFoundedTimeCalendarChange"
              @change="timeChangeHandle"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item prop="contractStatus">
            <el-select
              v-model="queryForm.contractStatus"
              clearable
              placeholder="合同状态"
              style="width: 120px"
            >
              <el-option label="未回收" :value="0"></el-option>
              <el-option label="已回收" :value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="confirmationStatus">
            <el-select
              v-model="queryForm.confirmationStatus"
              clearable
              placeholder="确认书状态"
              style="width: 120px"
            >
              <el-option label="未寄出" :value="0"></el-option>
              <el-option label="已寄出" :value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-permission="['sys:sysUser:get']"
              v-model="queryForm.sysUserId"
              clearable
              filterable
              @visible-change="selectSysUsersChange"
              placeholder="请选择管理员"
            >
              <el-option label="未分配" :value="0"></el-option>
              <el-option
                v-for="item in sysUsers"
                :key="item.userId"
                :label="item.nickname"
                :value="item.userId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="queryForm.sortType"
              clearable
              placeholder="请选择排序"
            >
              <el-option
                label="订单金额由高到低"
                value="buyMoneyDesc"
              ></el-option>
              <el-option
                label="订单金额由低到高"
                value="buyMoneyAsc"
              ></el-option>
              <el-option
                label="打款时间由近及远"
                value="paymentTimeDesc"
              ></el-option>
              <el-option
                label="打款时间由远及近"
                value="paymentTimeAsc"
              ></el-option>
              <el-option
                label="成立时间由近及远"
                value="foundedTimeDesc"
              ></el-option>
              <el-option
                label="成立时间由远及近"
                value="foundedTimeAsc"
              ></el-option>
              <el-option
                label="到期时间由近及远"
                value="expireTimeDesc"
              ></el-option>
              <el-option
                label="到期时间由远及近"
                value="expireTimeAsc"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getListData">查询</el-button>
            <el-button @click="resetFieldsClick">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div style="margin-bottom: 20px;" v-if="userInfo != null">
        <el-descriptions
          title="用户信息"
          direction="vertical"
          :column="4"
          size="medium"
          border
        >
          <el-descriptions-item label="姓名"
            >{{ userInfo.fullname }}
          </el-descriptions-item>
          <el-descriptions-item label="性别">
            <el-tag size="small" v-if="userInfo.sex == 1">男</el-tag>
            <el-tag size="small" v-else-if="userInfo.sex == 2">女</el-tag>
            <el-tag size="small" v-else>保密</el-tag>
          </el-descriptions-item>
          <el-descriptions-item label="手机号"
            >
            <span @click='showUserPhoneNumber(userInfo)'
                  v-if='userInfo.showPhoneNumber != true'>{{ phoneFormat(userInfo.phone) }}</span>
            <span v-else>{{ userInfo.phone }}</span>
          </el-descriptions-item>
          <el-descriptions-item label="归属地"
            >{{ userInfo.phoneAddress }}
          </el-descriptions-item>
          <el-descriptions-item label="意向度">
            <el-rate v-model="userInfo.userRating" disabled></el-rate>
          </el-descriptions-item>
          <el-descriptions-item label="身份证号"
            >{{ userInfo.idNumber }}
          </el-descriptions-item>
          <el-descriptions-item label="身份证地址"
            >{{ userInfo.idAddress }}
          </el-descriptions-item>
          <el-descriptions-item label="生日"
            >{{ userInfo.birthday }}
          </el-descriptions-item>
          <el-descriptions-item label="邮箱"
            >{{ userInfo.email }}
          </el-descriptions-item>
          <el-descriptions-item label="QQ"
            >{{ userInfo.qq }}
          </el-descriptions-item>
          <el-descriptions-item label="微信"
            >{{ userInfo.wechat }}
          </el-descriptions-item>
          <el-descriptions-item label="是否已加微信">
            <el-tag
              size="small"
              type="success"
              v-if="userInfo.isAddWechat == true"
              >已添加
            </el-tag>
            <el-tag size="small" type="info" v-else>未添加</el-tag>
          </el-descriptions-item>
          <el-descriptions-item label="备注">
            {{ userInfo.remark }}
          </el-descriptions-item>
        </el-descriptions>
      </div>
      <div
        class="tool"
        style="background: #fafafa;display: flex;padding: 10px;border: 1px solid #ebeef5;border-bottom: 0;"
      >
        <div style="margin-right: 10px;">
          <el-button
            size="small"
            type="primary"
            v-if="userInfo != null"
            @click="AddOrderClick"
            >添加
          </el-button>
        </div>
        <div style="margin-right: 10px;margin-top: 5px;">
          <div
            style="display:inline-block;vertical-align:middle;font-size: 14px;margin-left: 5px;font-weight: bold;"
          >
            表行背景色含义：
          </div>
        </div>
        <div style="margin-right: 10px;margin-top: 5px;">
          <div
            style="background-color: #fadeba;width: 16px;height: 16px;display:inline-block;vertical-align:middle; border-radius: 3px;"
          ></div>
          <div
            style="display:inline-block;vertical-align:middle;font-size: 14px;margin-left: 5px;"
          >
            订单到期
          </div>
        </div>
        <div style="margin-right: 10px;margin-top: 5px;">
          <div
            style="background-color: #fababa;width: 16px;height: 16px;display:inline-block;vertical-align:middle; border-radius: 3px;"
          ></div>
          <div
            style="display:inline-block;vertical-align:middle;font-size: 14px;margin-left: 5px;"
          >
            到期超过20天
          </div>
        </div>
        <div style="flex: 1;text-align: right;">
          <el-button size="small" type="warning" @click="exportExcel" plain v-permission="['sys:order:export']">导出选中订单
          </el-button>
          <el-button size="small" type="primary" @click="getListData" plain
            >刷新列表
          </el-button>
        </div>
      </div>
      <div class="table">
        <el-table
          ref='tableRef'
          border
          v-loading="loading"
          :header-cell-style="{
            background: '#fafafa',
            color: '#333333',
            padding: '4px 0'
          }"
          :cell-style="{ padding: '4px 0' }"
          :data="tableData"
          style="width: 100%"
          @selection-change="handleSelectionChange"
          :row-class-name="tableRowClassName"
        >
          <el-table-column type="selection" width="55" />
          <el-table-column type="expand">
            <template #default="props">
              <el-descriptions title="" style="padding-left: 60px ">
                <el-descriptions-item label="受益人："
                  >{{ props.row.fullname }}
                </el-descriptions-item>
                <el-descriptions-item label="身份证："
                  >{{ props.row.idNumber }}
                </el-descriptions-item>
                <el-descriptions-item label="邮寄地址："
                  >{{ props.row.address }}
                </el-descriptions-item>
                <el-descriptions-item label="打款银行："
                  >{{ props.row.paymentBank }}
                </el-descriptions-item>
                <el-descriptions-item label="银行卡号："
                  >{{ props.row.paymentBankNumber }}
                </el-descriptions-item>
                <el-descriptions-item label="银行行号："
                >{{ props.row.bankNumber }}
                </el-descriptions-item>
                <el-descriptions-item label="收益补贴："
                  >{{ props.row.incomeSubsidy }}
                </el-descriptions-item>
                <el-descriptions-item label="赠送礼物："
                  >{{ props.row.giveGift }}
                </el-descriptions-item>
                <el-descriptions-item label="介绍人："
                  >{{ props.row.introducer }}
                </el-descriptions-item>
                <el-descriptions-item label="介绍赠礼："
                  >{{ props.row.introducerGift }}
                </el-descriptions-item>
                <el-descriptions-item label="备注："
                  >{{ props.row.remarks }}
                </el-descriptions-item>
                <el-descriptions-item label="创建人："
                  >{{ props.row.createSysUser.nickname }}
                </el-descriptions-item>
              </el-descriptions>
            </template>
          </el-table-column>
          <el-table-column
            property="product.title"
            label="产品名称"
            min-width="200"
          />
          <el-table-column property="fullname" label="受益人" width="80" />
          <el-table-column property="phone" label="联系电话" width="116">
            <template #default="scope">
              <span
                @click="showOrderPhoneNumber(scope.row)"
                v-if="scope.row.showPhoneNumber != true"
                >{{ phoneFormat(scope.row.phone) }}</span
              >
              <span v-else>{{ scope.row.phone }}</span>
            </template>
          </el-table-column>
          <el-table-column property="buyMoney" label="购买金额" width="80">
            <template #default="scope">
              {{ scope.row.buyMoney / 10000 }}万
            </template>
          </el-table-column>
          <el-table-column
            property="buyTerm"
            label="期限"
            align="center"
            width="60"
          >
            <template #default="scope"> {{ scope.row.buyTerm }}月</template>
          </el-table-column>
          <el-table-column
            property="yield"
            label="年化收益"
            align="center"
            width="80"
          >
            <template #default="scope"> {{ scope.row.yield }}%</template>
          </el-table-column>
          <el-table-column
            property="payInterestType"
            label="付息方式"
            width="110"
          >
            <template #default="scope">
              <template v-if="scope.row.payInterestType == 1"
                >每月付息
              </template>
              <template v-else-if="scope.row.payInterestType == 2"
                >每3个月付息
              </template>
              <template v-else-if="scope.row.payInterestType == 3"
                >每6个月付息
              </template>
              <template v-else-if="scope.row.payInterestType == 4"
                >每年付息
              </template>
              <template v-else-if="scope.row.payInterestType == 5"
                >按月付息
              </template>
              <template v-else-if="scope.row.payInterestType == 6"
                >自然季度付息
              </template>
              <template v-else-if="scope.row.payInterestType == 7"
                >自然半年付息
              </template>
              <template v-else-if="scope.row.payInterestType == 8"
                >按年付息
              </template>
            </template>
          </el-table-column>
          <el-table-column property="foundedTime" label="成立日" width="100" />
          <el-table-column property="paymentTime" label="打款日" width="100" />
          <el-table-column property="expireTime" label="到期日" width="100" />
          <el-table-column property="incomeSubsidy" label="收益补贴" />
          <el-table-column property="giveGift" label="赠送礼物" />
          <el-table-column property="contractStatus" label="合同" width="70">
            <template #default="scope">
              <template v-if="scope.row.contractStatus == 0">未回收</template>
              <template v-else-if="scope.row.contractStatus == 1"
                >已回收
              </template>
            </template>
          </el-table-column>
          <el-table-column
            property="confirmationStatus"
            label="确认书"
            width="70"
          >
            <template #default="scope">
              <template v-if="scope.row.confirmationStatus == 0"
                >未寄出
              </template>
              <template v-else-if="scope.row.confirmationStatus == 1"
                >已寄出
              </template>
            </template>
          </el-table-column>
          <el-table-column
            property="sysUser.nickname"
            label="管理员"
            width="80"
          />
          <el-table-column fixed="right" label="操作" width="130">
            <template #default="scope">
              <el-button
                type="text"
                size="small"
                @click="editOrderClick(scope.row)"
                v-permission="['sys:order:update']"
                >修改
              </el-button>
              <el-button
                type="text"
                size="small"
                @click="progressManageClick(scope.row)"
                v-permission="['sys:order:update']"
                >进度管理
              </el-button>
              <el-button
                type="text"
                size="small"
                @click="interestPaymentTableClick(scope.row)"
                v-permission="['sys:order:get']"
                >付息表
              </el-button>
              <el-button
                type="text"
                size="small"
                style="color: #ff4d4f"
                @click="deleteOrderClick(scope.row)"
                v-permission="['sys:order:delete']"
                >删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="text-align: center;padding-top: 20px;">
        <el-pagination
          layout="total, sizes, prev, pager, next, jumper"
          :page-sizes="[10, 20, 50, 100, 200, 500]"
          :page-size="queryForm.limit"
          :current-page="queryForm.page"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </el-card>

    <add-or-update-order
      v-if="showAddOrUpdateOrderDialog"
      v-model="showAddOrUpdateOrderDialog"
      :user-id="queryForm.userId"
      :order-id="selectedItemId"
      @close="getListData"
    ></add-or-update-order>

    <progress-manage
      v-if="showProgressManageDialog"
      v-model="showProgressManageDialog"
      :id="selectedItemId"
      @close="getListData"
    ></progress-manage>

    <interest-payment-table
      v-if="showInterestPaymentTableDialog"
      v-model="showInterestPaymentTableDialog"
      :id="selectedItemId"
      @close="getListData"
    ></interest-payment-table>
  </div>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { getUserOrderPage, deleteUserOrder, userOrderExport } from '@/api/user-order'
import { getSysUserList } from '@/api/sys-user'
import { getUserById } from '@/api/user'
import { getTime } from '@/api/common'
import AddOrUpdateOrder from './components/AddOrUpdateOrder'
import ProgressManage from './components/ProgressManage'
import { getProductPage } from '@/api/product'
import { useRoute } from 'vue-router'
import dayjs from 'dayjs'
import InterestPaymentTable from './components/InterestPaymentTable'
import { ElMessage, ElMessageBox } from 'element-plus'
import { getSensitiveOrderPhone, getSensitiveUserPhone } from '../../api/sensitive'

const route = useRoute()
const queryFormRef = ref(null)
const queryForm = ref({
  page: 1,
  limit: 10,
  sort: 'createTime',
  order: 'desc',
  userId: route.params.userId,
  sysUserId: '', // 管理员id
  phone: '',
  fullname: '',
  productId: '',
  moneyStart: '',
  moneyEnd: '',
  paymentTimeStart: '',
  paymentTimeEnd: '',
  foundedTimeStart: '',
  foundedTimeEnd: '',
  expireTimeStart: '',
  expireTimeEnd: '',
  contractStatus: '',
  confirmationStatus: '',
  sortType: ''
})
const tableRef = ref()
// 打款日
const paymentTime = ref([])
const selectPaymentTimeCalendarChange = date => {
  queryForm.value.paymentTimeStart = formatDate(date[0])
  queryForm.value.paymentTimeEnd = formatDate(date[1])
}
// 成立日
const foundedTime = ref([])
const selectFoundedTimeCalendarChange = date => {
  queryForm.value.foundedTimeStart = formatDate(date[0])
  queryForm.value.foundedTimeEnd = formatDate(date[1])
}
// 到期日
const expireTime = ref([])
const selectExpireTimeCalendarChange = date => {
  queryForm.value.expireTimeStart = formatDate(date[0])
  queryForm.value.expireTimeEnd = formatDate(date[1])
}

const timeChangeHandle = () => {
  if (paymentTime.value == null) {
    queryForm.value.paymentTimeStart = ''
    queryForm.value.paymentTimeEnd = ''
  }
  if (foundedTime.value == null) {
    queryForm.value.foundedTimeStart = ''
    queryForm.value.foundedTimeEnd = ''
  }
  if (expireTime.value == null) {
    queryForm.value.expireTimeStart = ''
    queryForm.value.expireTimeEnd = ''
  }
}

/**
 * 日期格式化

 * @param date
 * @returns {string}
 */
const formatDate = date => {
  return dayjs(date).format('YYYY-MM-DD')
}

/**
 * 重置查询表单
 */
const resetFieldsClick = () => {
  queryFormRef.value.resetFields()
  paymentTime.value = []
  foundedTime.value = []
  queryForm.value = []
  queryForm.value.paymentTimeStart = ''
  queryForm.value.paymentTimeEnd = ''
  queryForm.value.foundedTimeStart = ''
  queryForm.value.foundedTimeEnd = ''
  queryForm.value.expireTimeStart = ''
  queryForm.value.expireTimeEnd = ''
}

/**
 * 获取用户基本信息
 */
const userInfo = ref(null)
const getUserInfo = async userId => {
  userInfo.value = await getUserById(userId)
}
if (queryForm.value.userId) {
  getUserInfo(queryForm.value.userId)
}

const tableRowClassName = row => {
  const diffDay = dayjs(row.row.expireTime).diff(serverTime.value, 'day')
  if (diffDay <= 0 && diffDay > -20) {
    return 'expire-row'
  } else if (diffDay <= -20) {
    return 'done-row'
  }
  return ''
}

const serverTime = ref(dayjs(new Date()))
const getTimeData = async () => {
  serverTime.value = dayjs(await getTime())
}

/**
 * 表格数据相关
 */
const loading = ref(false)
const tableData = ref([])
const total = ref(0)
// 获取数据的方法
const getListData = async () => {
  loading.value = true
  const result = await getUserOrderPage(queryForm.value)
  tableData.value = result.list
  total.value = result.total
  loading.value = false
}
getTimeData()
getListData()

/**
 * 获取管理员列表
 */
const sysUsers = ref([])
const getSysUsers = async () => {
  sysUsers.value = await getSysUserList()
}
// 下拉框出现时候再获取
const selectSysUsersChange = val => {
  if (val === true && sysUsers.value.length <= 0) {
    getSysUsers()
  }
}

/**
 * 分页数量改变事件
 */
const handleSizeChange = val => {
  queryForm.value.limit = val
  getListData()
}
const handleCurrentChange = val => {
  queryForm.value.page = val
  getListData()
}

/**
 * 选中的列表id数组
 */
const selectItemIds = ref([])
const handleSelectionChange = val => {
  selectItemIds.value = []
  val.forEach(row => {
    selectItemIds.value.push(row.id)
  })
}

/**
 * 要操作的项目id
 */
const selectedItemId = ref(0)

/**
 * 删除订单点击事件
 * @param row
 */
const deleteOrderClick = row => {
  ElMessageBox.confirm('确认要删除该订单?', 'Warning', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(async () => {
      await deleteUserOrder(row.id)
      ElMessage.success('删除成功')
      getListData()
    })
    .catch(() => {})
}

/**
 * 产品名称远程搜索
 * @type {ToRef<*[]>}
 */
const productOptions = ref([])
const productRemoteMethod = async query => {
  if (query !== '' && query.length > 1) {
    loading.value = true
    const productResult = await getProductPage({ title: query })
    productOptions.value = productResult.list
    loading.value = false
  } else {
    productOptions.value = []
  }
}

/**
 * 显示添加订单对话框
 */
const showAddOrUpdateOrderDialog = ref(false)
const AddOrderClick = () => {
  selectedItemId.value = 0
  showAddOrUpdateOrderDialog.value = true
}

/**
 * 显示修改订单对话框
 */
const editOrderClick = row => {
  selectedItemId.value = row.id
  showAddOrUpdateOrderDialog.value = true
}

/**
 * 显示进度管理对话框
 */
const showProgressManageDialog = ref(false)
const progressManageClick = row => {
  selectedItemId.value = row.id
  showProgressManageDialog.value = true
}

/**
 * 显示付息表对话框
 */
const showInterestPaymentTableDialog = ref(false)
const interestPaymentTableClick = row => {
  selectedItemId.value = row.id
  showInterestPaymentTableDialog.value = true
}

/**
 * 手机号格式化
 * @param phone
 * @returns {string|*}
 */
const phoneFormat = phone => {
  try {
    const start = phone.slice(0, 3)
    const end = phone.slice(-4)
    return start + '****' + end
  } catch (ex) {
  }
  return phone
}

const showUserPhoneNumber = row => {
  row.showPhoneNumber = true
  // 请求获取用户敏感手机号
  getSensitiveUserPhone(row.id).then(res => {
    row.phone = res
  })
}

const showOrderPhoneNumber = row => {
  for (let j = 0; j < tableData.value.length; j++) {
    tableData.value[j].showPhoneNumber = false
  }
  row.showPhoneNumber = true
  // 请求获取订单敏感手机号
  getSensitiveOrderPhone(row.id).then(res => {
    row.phone = res
  })
}

const exportExcel = () => {
  if (selectItemIds.value.length > 0) {
    userOrderExport(selectItemIds.value)
  }
}
</script>

<style>
.el-table .expire-row {
  background-color: #fadeba !important;
}

.el-table .done-row {
  background-color: #fababa !important;
}
</style>
