import request from '@/utils/request'

/**
 * 用户订单分页查询
 * @param params
 * @returns {*}
 */
export const getUserOrderPage = params => {
  return request({
    url: '/userOrder/page',
    method: 'GET',
    params
  })
}

/**
 * 获取用户订单信息
 * @param params
 * @returns {*}
 */
export const getUserOrderById = orderId => {
  return request({
    url: `/userOrder/${orderId}`,
    method: 'GET'
  })
}

/**
 * 添加用户订单
 */
export const addUserOrder = data => {
  return request({
    url: '/userOrder',
    method: 'POST',
    data
  })
}

/**
 * 修改用户订单
 * @param data
 * @returns {*}
 */
export const updateUserOrder = data => {
  return request({
    url: '/userOrder',
    method: 'PUT',
    data
  })
}

/**
 * 删除用户订单
 * @param data
 * @returns {*}
 */
export const deleteUserOrder = orderId => {
  return request({
    url: `/userOrder/delete/${orderId}`,
    method: 'DELETE'
  })
}

/**
 * 修改用户订单进度
 * @param data
 * @returns {*}
 */
export const updateUserOrderProgress = data => {
  return request({
    url: '/userOrder/updateProgress',
    method: 'PUT',
    data
  })
}

/**
 * 批量导出用户产品订单
 * @param ids
 * @returns {*}
 */
export const userOrderExport = ids => {
  const data = {
    items: ids
  }
  return request({
    url: '/userOrder/export',
    method: 'POST',
    data,
    responseType: 'blob'
  })
}
